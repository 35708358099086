.notification{
    background-color: #FB404B;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
  }

  .drawer-notification{
    position: absolute;
    background-color: #FB404B;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
    margin-left: 120px;
    margin-top: -5px;
  }

  .navbar-notification{
    position: absolute;
    background-color: #FB404B;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
    margin-left: -25px;
    margin-top: 5px;
  }

  .navbar-notification1{
    background-color: #FB404B;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    height: 18px;
    font-size: 12px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 18px;
  }

  /* Start of Draft JS Mention Plugin Styles */
  .m6zwb4v,.m6zwb4v:visited{
    color:#575f67;
    cursor:pointer;
    display:inline-block;
    background:#e6f3ff;
    padding-left:2px;
    padding-right:2px;
    border-radius:2px;
    -webkit-text-decoration:none;
    text-decoration:none;
}
.m6zwb4v:hover,.m6zwb4v:focus{
    color:#677584;
    background:#edf5fd;
    outline:0;
}
.m6zwb4v:active{
    color:#222;
    background:#455261;
}
 .mnw6qvm{
    border:1px solid lightgray;
    position:absolute;
    min-width:220px;
    max-width:440px;
    max-height: 350px;
    border-radius:2px;
    overflow-y: auto;
    cursor:pointer;
    padding-top:8px;
    padding-bottom:8px;
    z-index:2;
    background-color: #b6dbfd;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-flex-direction:column;
    -ms-flex-direction:column;
    flex-direction:column;
    box-sizing:border-box;
    -webkit-transform:scale(0);
    -ms-transform:scale(0);
    transform:scale(0);
}
 .m1ymsnxd{
    opacity:0;
    -webkit-transition:opacity 0.25s cubic-bezier(0.3,1.2,0.2,1);
    transition:opacity 0.25s cubic-bezier(0.3,1.2,0.2,1);
}
 .m126ak5t{
    opacity:1;
}
 .mtiwdxc{
    padding:7px 10px 3px 10px;

}
.mtiwdxc:active{
    background-color:#ccffd9;
}
 .myz2dw1{
    padding:7px 10px 3px 10px;
    background-color:#d8fae0;
}
.myz2dw1:active{
    background-color:#ccffd9;
    
}
 .mpqdcgq{
    display:inline-block;
    margin-left:8px;
    white-space:nowrap;
    overflow:hidden;
    color: black;
    text-overflow:ellipsis;
    max-width:368px;
    font-size:0.9em;
    margin-bottom:0.2em;
}
.mpqdcgq:active{
  color: #222;
}
 .m1mfvffo{
    display:inline-block;
    width:24px;
    height:24px;
    border-radius:12px;
}
/* End of Draft JS Mention Plugin Styles */


.assign-client-select__menu {
  z-index: 99999!important;
}

.assign-client-select__menu-list {
  z-index: 99999!important;
}

.assign-client-select__option {
  z-index: 99999!important;
}

.chakra-accordion__item .chakra-collapse{
  overflow: visible!important;
}

.chakra-text a{
  color: #5ca6e2;
}

.chakra-text a:hover{
  text-decoration: underline;
}

.w-full{
  width: 100%
}


.animated-box-light {
  --border-size: 2px;
  --border-angle: 0turn;
  background-image: conic-gradient(from var(--border-angle), rgb(255, 255, 255), rgb(255, 255, 255) 50%, rgb(255, 255, 255)), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 2s linear infinite;
          animation: bg-spin 2s linear infinite;
}

.animated-box-dark {
  --border-size: 2px;
  --border-angle: 0turn;
  background-image: conic-gradient(from var(--border-angle),#1a202c, #1a202c 50%, #1a202c), conic-gradient(from var(--border-angle), transparent 20%, #08f, #f03);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)),cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-animation: bg-spin 2s linear infinite;
          animation: bg-spin 2s linear infinite;
}
@-webkit-keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}
.animated-box:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

ul li::marker{
  font-size: 0px;
}

.datepicker-errormessage{
  align-self: flex-start;
}