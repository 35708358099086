.editor {
    position:relative;
    cursor: text;
    bottom: 0;
    background: #fefefe;
    width: 100%;
    border-top: 1px solid lightgray;
  }
  .darkeditor {
    position:relative;
    cursor: text;
    bottom: 0;
    background: #1e2638;
    color: white;
    width: 100%;
    border-top: 1px solid rgb(97, 97, 97);
  }
  
  .editor :global(.public-DraftEditor-content) {
    min-height: 45px;
    max-height: 150px;
    margin-right: 5em;
    padding: 1em;
    overflow-x: none;
    overflow-y: auto;
  }
  .darkeditor :global(.public-DraftEditor-content) {
    min-height: 45px;
    max-height: 150px;
    margin-right: 5em;
    padding: 1em;
    overflow-x: none;
    overflow-y: auto;
  }

  .editorDisabled {
    position:absolute;
    cursor: not-allowed;
    bottom: 0;
    background: #fefefe;
    width: 100%;
    border-top: 1px solid lightgray;
  }
  .darkeditorDisabled {
    position:absolute;
    cursor: not-allowed;
    bottom: 0;
    background: #1e2638;
    color: white;
    width: 100%;
    border-top: 1px solid rgb(97, 97, 97);
  }
  
  .editorDisabled :global(.public-DraftEditor-content) {
    min-height: 45px;
    max-height: 150px;
    margin-right: 3em;
    padding: 1em;
    overflow-x: none;
    overflow-y: auto;
  }
  .darkeditorDisabled :global(.public-DraftEditor-content) {
    min-height: 45px;
    max-height: 150px;
    margin-right: 3em;
    padding: 1em;
    overflow-x: none;
    overflow-y: auto;
  }



